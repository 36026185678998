





















import { Component, Vue, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexCharsSeries } from "@/shared/dtos/DashboardsDtos/ApexCharsSeries";
@Component({
  components: { VueApexCharts }
})
export default class ColumnsChart extends Vue {
  @Prop({ default: [] }) series!: ApexCharsSeries[];
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) formato!: string;
  @Prop({ default: "" }) avatar!: string;
  @Prop({ default: "" }) color!: string;

  public created() {}

  public get chartOptions() {
    return {
      series: this.series,
      chart: {
        type: "bar",
        height: 350,
        toolbar: { show: false },
        dropShadow: {
          enabled: true,
          top: 5,
          left: 0,
          blur: 4,
          opacity: 0.1
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: ["01"]
      },
      fill: {
        opacity: 1
      },
      tooltip: {}
    };
  }
}
